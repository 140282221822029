import './App.css';

function App() {
  return (
    <div className="App">
      <img src={`hamburger.svg`} className="hamburger" alt="hamburger menu" />
      <div className="App-header">
      <img src={`rela-logo.svg`} className="App-logo" alt="logo" />
      <h1>rela.v</h1>
      <h2 className="web-developer">web developer</h2>
      </div>
      <div className="next-body">
      <img src={`down-arrow.svg`} className="down-arrow" alt="logo" />
        <h2 className="about">about</h2>
        <p className="mission">My mission is to help leaders produce transformative change through web design & development, ML/AI development & integration into business operations, and brand design & strategy.</p>
        <h2 className="services">services</h2>
        <div className="services-list">
          <h3 className="service">1. web design & development</h3>
          <img src={`carbon_application-web.svg`} className="web" alt="logo" />
          <h3 className="service">2. ml/ai development & integration</h3>
          <img src={`brainai.svg`} className="brainai" alt="logo" />
          <h3 className="service">3. brand design & strategy</h3>
          <img src={`pencil-circle.svg`} className="pencil-circle" alt="logo" />
        </div>
        <h2 className="contact">contact</h2>
        <div className="contact-information">
          <img src={`github.svg`} className="github" alt="logo" />
          <img src={`twitter.svg`} className="twitter" alt="logo" />      
        </div>
        <a href="mailto:rela.vergis@gmail.com">rela.vergis@gmail.com</a>
      </div>
    </div>
  );
}

export default App;
